<template>
  <div>
    <DataGridFactory
      :dataSource="store.DocArquivosGerais"
      :enabledActions="false"
      :nameToExport="'Arquivos Gerais'"
    >
      <template v-slot:column>
        <DxColumn
          data-field=""
          type="buttons"
          caption="Arquivo"
          fixed-position="left"
        >
          <DxButton
            hint="Fazer download do arquivo"
            icon="mdi mdi-cloud-download"
            :on-click="DownloadArchive"
          />
        </DxColumn>
        <DxColumn
          data-field="descricao"
          data-type="string"
          caption="Descrição"
        />
        <DxColumn
          data-field="competencia"
          data-type="string"
          caption="Competência"
        />
        <DxColumn data-field="dataUpload" data-type="date" caption="Upload" />
      </template>
      <template v-slot:footer>
        <DxExport :enabled="true" />
      </template>
    </DataGridFactory>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>