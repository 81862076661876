import { DxColumn, DxExport, DxButton } from 'devextreme-vue/ui/data-grid';
import DataGridFactory from '../../datagrid-factory/index.vue';
import { documentoStore } from '../../../store/modules/documentos';
import { defineComponent } from 'vue'; 
import { portalContadorBaseUrl } from '@/helpers/constans';
export default defineComponent({
  name: 'ArquivosGerais',
  setup() {
    const store = documentoStore()

    function DownloadArchive(rowData: any) {
      const row = rowData.row.data.caminho          
      const archive = portalContadorBaseUrl+row;
      open(archive)
    }

    return {
      store,
      DownloadArchive
    }
  },
  components: {
    DataGridFactory,
    DxColumn,
    DxExport,
    DxButton
  }
})
